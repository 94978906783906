.day-picker__wrapper {
    background: #121212;
    box-shadow: 0px 4.64745px 13.9424px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    padding: 16px 20px 16px 17px;
}

.rdp {
    --rdp-cell-size: 36px !important;
    --rdp-accent-color: #8E75FF;
    --rdp-background-color: #836fe0d6;
    --rdp-accent-color-dark: #8E75FF;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    margin: 0 !important;
    font-family: 'Inter', sans-serif;
}

.rdp-tfoot {
    display: none;
}

.rdp-caption_label {
    font-weight: 600 !important;
    font-size: 16.2661px !important;
    line-height: 20px !important;
    /* identical to box height */

    color: #E4E4E4 !important;
}

.rdp-nav_button {
    width: 18.59px !important;
    height: 18.59px !important;
    background: #F1F6FE !important;
    box-shadow: 0px 1.16186px 4.64745px rgba(190, 190, 190, 0.15);
    border-radius: 50%;
}

.rdp-nav_button:first-child {
    margin-right: 6.97px;
}

.rdp-nav_button svg {
    color: #3B7BE0 !important;
    height: 10px;
    width: 10px;
}

.rdp-head_cell {
    font-weight: 500;
    font-size: 9.2949px;
    line-height: 11px;

    color: #E4E4E4;
}

.rdp-weeknumber,
.rdp-day {
    margin: 0;
    border-radius: 0px;
    font-weight: 500;
    font-size: 13.9424px;
    line-height: 17px;
    /* identical to box height */


    color: #E4E4E4;
}

.rdp-day_selected {
    background: #8E75FF;
}

.cstm-date__input {

    width: 100% !important;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    border: 1.5px solid #FFFFFF;
    border-radius: 5px;
    background: transparent;
    color: white;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.03em;
}

.date_icon-wrapper {
    height: 24px;
    width: 24px;
    background-image: url("../img/date.svg") !important;
    left: 11px;
    background-repeat: no-repeat;
    top: 32%;

}

.cstm-date__input.active,
.cstm-date__input:focus {
    border: 1.5px solid #8E75FF !important;
    box-shadow: none !important;
    outline: none;
}

.example-custom-input:focus-visible {
    border-bottom-color: #b51148;
    outline: none;
}

.cstm-date__input-wraper {
    margin-bottom: 17px;
}

.cstm-start__date-wrapper {
    margin-right: 14px;
}

.dropzone__wrapper {
    height: 149px;
    background: transparent;
    border: 1px solid #8C8C8C;

    border-radius: 5px;
}
.dropzone__wrapper.error{
    border: 1px solid #ff2d55 !important;

}

.dropzone-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    color: #8C8C8C;
}

.dropzone-upload__btn {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 15px !important;
    color: #FFFFFF !important;
    background: #8E75FF !important;
    border-radius: 21px !important;
    padding: 10px 20px !important;
    margin-bottom: 17px;
}

.modal {
    background: rgba(0, 0, 0, 0.37) !important;
    backdrop-filter: blur(6.5px) !important;
}

.upload-success__modal_content-wrapper {
    width: 422px !important;
    margin: 2em auto !important;
}


/*PAGINATION*/
.pagination-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    list-style-type: none;
    margin-bottom: 20px;
    margin-top: 25.5px;
    padding-left: 0 !important;
}





.pagination-wrapper .paginationItem {
    display: flex;
}

.pagination-wrapper .paginationItem button {
    background: transparent;
    overflow: hidden;
    color: #BCBCBC;
    width: 37px;
    height: 37px;
    border: 1px solid #BCBCBC;
    border-radius: 6px;
    font-weight: 400;
    font-size: 15px;
}

.pagination-wrapper .paginationItem button:hover {
    background: #8E75FF;
    border: 1px solid #8E75FF;
    /* font-weight: 700; */
    color: #fff;
    cursor: pointer;
    opacity: 0.6;
}

.pagination-wrapper .paginationItem button:disabled {
    pointer-events: none;
    opacity: 0.5;
}

.pagination-wrapper .active-page button {
    background: #8E75FF;
    border: 1px solid #8E75FF;
    color: #fff;
}

.pagination-wrapper .arrowButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    color: #fff !important;
    transition: all 500ms;
    padding: 0px 10px;
}

.pagination-wrapper .arrowButton.left {
    margin-right: 9px !important;
}

.pagination-wrapper .arrowButton.right {
    margin-left: 11px !important;
}

.pagination-wrapper .arrowButton svg {
    width: 1em;
    height: 1em;
}

.pagination-wrapper .arrowButton:disabled {
    color: #8C8C8C
}

.pagination-wrapper .arrowButton:active {
    color: #fff
}

.pagination-wrapper .arrowButton:hover {
    background: transparent !important;
    color: #fff !important;
}

.pagination-wrapper .arrowButton .dots {
    line-height: 34px;
    margin: 5px 10px;
}

.pagination-wrapper .arrowButton .paginationSelector {
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 10px;
}