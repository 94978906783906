.profile-settings__menu_container {
    position: sticky;
    top: 115px;
}

.profile-settings_text {
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;

    color: #8C8C8C;
    margin-bottom: 14px;
    padding-left: 14px;


}

.profile-settings__menu_wrapper {
    list-style: none;
    padding-left: 0px;

}

.profile-settings__menu_link {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: px;
    color: #8C8C8C;
    padding: 14px;
    cursor: pointer;

}

.profile-settings__menu_link.active {

    background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    color: #FFFFFF;
    /* padding: 14px; */
    /* margin-bottom: 14px; */

}

.creator-profile-image__wrapper {
    background: #383838;
    border: 0.882927px solid #8E75FF;
    font-weight: 400;
    font-size: 70.3889px;
    line-height: 77px;
    letter-spacing: -0.03em;
    color: #A9A9A9;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin-right: 18px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../img//dateIcon.svg') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}

.change-creator-profile__image {
    bottom: 0px;
    right: 0px;
    width: 47px;
    height: 47px;
    background: linear-gradient(118.94deg, #5F42E2 12.08%, #8942CA 96.14%);
    border-radius: 50%;
    cursor: pointer;
}

.password-settings__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
}

.password-settings__subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    color: #FFFFFF
}

.empty-banklist_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */


    color: #FFFFFF;
}

.empty-banklist_sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: #8C8C8C;
    width: 75%;

}

.confirm-transaction__modal_sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: #8C8C8C;
}

.mask-input {
    -webkit-text-security: disc !important;
}