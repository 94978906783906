.transactions--value__wrapper {
    border-bottom: 1px dashed #656565;
    padding-bottom: 25px !important;
}

.recent_earnings_text {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF
}

.redirect-earnings__link {
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    text-align: right;
    color: #8E75FF;
    margin-right: 8px;
}

.recent-earnings__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #8C8C8C;
}

.content-table-body.transactions-earnings__table td {
    padding: 21px 0 !important;
}

.user-withdrawable__amount {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    color: #FFFFFF;
}

.confirm-transaction__modal_text {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.transactions-details__wrapper {
    background: #252525;
    backdrop-filter: blur(4px);
    border-radius: 10px;
    /* min-height: 173px; */
    margin: 27px 0 29px 0;
    padding: 23px 30px;

}

.analytics-card.transaction__modal {
    padding: 2.5em 3em;
}

.transaction_details_name {
    font-weight: 400;
    font-size: 12px;
    line-height: 148%;
    color: #C4C4C4;
}

.transaction_details_value {
    font-weight: 400;
    font-size: 12px;
    line-height: 148%;
    color: #FFFFFF;
}

.transaction_details_container:not(:last-child) {
    margin-bottom: 14px !important;
}

.set_transaction__label {
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #FFFFFF;
}

.set-transaction_pin__guide {
    font-weight: 400;
    font-size: 13px;
    line-height: 148%;
    /* or 19px */

    text-align: center;

    color: #C4C4C4;
}

.set-pin__form {
    margin: 40px 0 45px 0;
}

.transactions-pin__icon {
    top: -45px;
    left: 40%;
}

.cstm-select__aacount-input {
    background: #262626;
    border: 1px solid #8C8C8C;
    /* backdrop-filter: blur(4px); */
    border-radius: 5px;
    padding: 13px 22px !important;
    color: white !important;
}

.cstm-select__aacount-input:focus-within {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #C084FF !important;
    border-radius: 5px !important;
    background: transparent !important;
}

.cstm-select__placeholder {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.03em;
    color: #8C8C8C;

}

.user-saved__bank-list {
    top: 100%;
    left: 0;
    width: 100%;
    background: rgba(217, 217, 217, 0.19);
    border: 1.5px solid #444444;
    backdrop-filter: blur(14px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 11px;
    padding: 16px 22px;
}

.withdraw-outline__btn {
    border: 1px solid #8E75FF !important;
    border-radius: 100px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 19px !important;
    text-align: center !important;
    color: #FFFFFF !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

@media(max-width:767px) {
    .user-saved-bank.w-75 {
        width: 100% !important;
    }

    .user-saved__bank-list {
        padding: 10px;
    }

    .withdraw-outline__btn-wrapper.w-75 {
        width: 100% !important;
    }

    .wallet-balance.analytics-card_value {
        font-size: 30px;
    }
}