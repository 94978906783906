.guide-card-wrapper {

    border-radius: 28px;
    height: 233px;
    background-image: url('../img/gbg1.png');
    backdrop-filter: blur(15px);
    background-repeat: no-repeat;
    /* background-position:0%; */
    border-radius: 28px;
    border: 1px solid #8C8C8C;
    object-fit: cover;
    background-size: 110%;
    overflow: hidden;
}

.guide-card__title-container {
    bottom: 0;
    width: 100%;
    padding: 26px 0;
    background: rgba(18, 18, 18, 0.63);
    backdrop-filter: blur(42.5px);
    border-radius: 0px 0px 28px 28px;
}

.guide-card__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */
    color: #FFFFFF;
    margin-bottom: 5px;
}

.faq-sections__wrapper {
    list-style-type: none;
    padding: 0;
}

.faq-section {
    position: relative;
    border-radius: 0.75rem;
    background: linear-gradient(30deg, #222121 3%, #8C8C8C 70%, #121212 100%, #8C8C8C 20%, #8C8C8C 70%);
    border-radius: 33px;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);

    padding: 12px 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    color: #8C8C8C;
    cursor: pointer;
}

.faq-section.active {
    background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;
}

.faq-section.active::after {
    background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
    background-color: unset !important;

}

.faq-section::after {
    content: "";
    position: absolute;
    background-color: #262626;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    z-index: -1;
    border-radius: 33px;

}

.accordion-item {
    margin-bottom: 8px !important;
    padding: 15px 20px 0px 20px;
    /* border: 1.5px solid #333333;
    border-radius: 5px; */
    border-radius: 5px !important;
    border: 1px solid #8C8C8C !important;
    transition: all 0.1s ease-in-out;
    cursor: pointer;


}

.accordion-item.active {
    border: 1.5px solid #5F42E2 !important;
    border-radius: 5px !important;

    background: #383838;
}

.accordion-item.active .accordion-title {
    border: none;
    border-bottom: 1px solid #8C8C8C;
    border-radius: 0px !important;
    color: #9E88FF;
    ;
    font-weight: 500;



}

.accordion-title {

    padding-bottom: 20px;
    font-weight: 400;
    font-size: 17px;
    line-height: 167.5%;
    /* or 25px */


    color: #FFFFFF;

}

.accordion-title__wrapper {
    cursor: pointer;
}

.accordion-content {
    padding: 18px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 167.5%;
    /* or 25px */


    color: #fcfafa;

}

.faq-accordion__wrapper {
    width: 100%;
}