.filter-icon__wrapper {
  /* width: 97px; */
  /*height: 39px; */
  background: #383838;
  border: 1.5px solid #444444;
  border-radius: 5px;
  justify-content: center;

  padding: 0px 24px 0px 22px !important;
  height: 36px;
}

.filtering-active__wrapper {
  background: #121212;
  border: 1.5px solid #444444;
  border-radius: 5px;
  padding: 0px 30px 0px 24px !important;
  height: 36px;
}

.filtering-active__wrapper > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.03em;

  color: #ff6c6c;
}

.filter-content__text {
  font-size: 14px;
  letter-spacing: -0.03em;
  margin-left: 9.75px;

  color: #8c8c8c;
}

.create-content__btn {
  background: #8e75ff !important;
  /* border: 1.5px solid #444444 !important; */
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 15px !important;
  letter-spacing: -0.03em !important;
  color: #fff !important;
  transition: all ease-in-out 5ms !important;
  height: 39px;
}

.create-content__btn:hover {
  background: #765af3 !important;
}

.search-content__wrapper {
  border: 1.5px solid #444444;
  border-radius: 5px;
  padding: 6px 2px 8px 15px;
  margin-right: 10px;
  width: 290px;
}

.search-content__input {
  border: none;
  background: transparent;
  padding-left: 3px;
  padding-right: 3px;

  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.03em;
  width: 100%;
}

.search-content__input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.search-content__input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.03em;

  color: #8c8c8c;
}

.content-table.withdraw {
  margin-top: 31px !important;
  /* height: calc(100vh - 250px) !important; */
  /* min-height: 540px !important; */
  padding: 22px 29px 40px 29px !important;
  height: unset !important;
  transition: all ease-in-out 1ms;
}

.content-table.withdraw.no-side__padding {
  padding: 16px 0px 12px 0px !important;
}

.side-padding {
  padding-left: 29px !important;
  padding-right: 29px !important;
}

.content-table.withdraw.rss-upload--form__wrapper {
  min-height: 365px !important;
}

.cstm-table__head {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;

  color: #ffffff;
}

.sort-icon__wrapper {
  margin-left: 10.2px;
  cursor: pointer;
}

.empty-content__state {
  width: 100%;
  height: 370px !important;
}

.cstm-table__head-wrapper {
  padding: 10px 0 22px 0;
  border-bottom: 1px solid #656565;
  width: 100%;
}

.cstm-table__head.last {
  padding-right: 27px;
}

.empty-content__header {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 13px !important;
}

.empty-content__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 172%;
  text-align: center;
  letter-spacing: -0.03em;

  color: #8c8c8c;
}

.text-cstm__primary {
  color: #8e75ff;
}

.empty-content__text:last-child {
  width: 75%;
  margin: auto;
}

.content-status {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  border-radius: 24px;
  padding: 8.5px 15px 10px 15px;
  line-height: 14px !important;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-status.live {
  color: #57f2a8;
  background: rgba(87, 242, 168, 0.15);
  width: 73%;
}

.content-status.review {
  color: #f8961e;
  background: rgba(248, 150, 30, 0.15);
}

.content-status.rejected {
  color: #ff6656;
  background: rgba(255, 102, 86, 0.15);
}
.content-details.content-status {
  width: 10% !important;
}

.td-text__container {
  margin-top: 13px !important;
}

.content-table-body td {
  padding: 13px 0 !important;
  vertical-align: middle;
}

.content-table-body tr {
  border-bottom: 1px solid #656565 !important;
}

.content-table th,
.content-table td {
  border-color: #656565 !important;
}

.table-title {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-right: 53px;
}

.table-details__wrapper {
  margin-bottom: 36.75px;
}

/* .content-table-body td.td-img__container {
    padding: 16px 0 10px 0 !important;

} */

.content-select__wrapper {
  padding-top: 17px;
  padding-bottom: 32px;
  border-bottom: 1px dashed #656565;
}

.content-select__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: #ffffff;
  margin-right: 100px;
}

.content-type__select {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #8c8c8c;
  margin-left: 10px;
  cursor: pointer !important;
}

.radio_label {
  margin-right: 40px;
}

.content-select_form input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translateY(-0.03em);
  background: #d9d9d9;
  border: 1px solid #656565;
  cursor: pointer;
  position: relative;
}

.content-select_form input[type="radio"]::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #8e75ff;
  top: 0;
}

.content-select_form input[type="radio"]:checked::before {
  transform: scale(1);
}

.content-select_form input[type="radio"]:checked + label {
  background-color: #8e75ff;
}

.upload-content__form.rss-input__form label,
.rss-uploaded_content__form label {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 8px;
}

.upload-content__form.rss-input__form {
  margin-top: 24px;
}

.upload-content__form.rss-input__form input:not(.cstm-react__select__input),
.rss-uploaded_content__form input,
.rss-uploaded_content__form textarea {
  background: #262626;
  border: 1px solid #8c8c8c;
  /* backdrop-filter: blur(4px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
  padding: 13px 22px !important;
  color: white !important;
}

.upload-content__form.rss-input__form input:disabled,
.rss-uploaded_content__form input:disabled,
.rss-uploaded_content__form textarea:disabled,
.cstm-react__select__wrapper.cstm-react__select--is-disabled {
  background-color: #3e3e3e !important;
  border: 1px solid #8c8c8c !important;
  backdrop-filter: blur(4px) !important;
  /* font-weight: 400;
    font-size: 14px;
    line-height: 15px; */
  color: #8c8c8c !important;
}

.upload-content__form.rss-input__form input {
  width: 60%;
}

.upload-content__form.rss-input__form .form_container {
  padding-bottom: 50px;
  border-bottom: 1px solid #656565;
}

.upload-content__form.rss-input__form button {
  /* margin-top: 33px; */
  width: 30%;
  /* padding-top: 13px !important;
    padding-bottom: 13px !important; */
}

.upload-content__form.rss-input__form input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #8c8c8c;
}

.upload-content__form.rss-input__form input {
  color: white !important;
}

.metadata__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */
  color: #ffffff;
  margin-bottom: 34px;
  margin-top: 23px;
}

.form-input__container {
  margin-bottom: 20px;
}

.uploaded-content__description {
  height: 153px !important;
}

.input-info__wrapper {
  margin-top: 10px;
  margin-left: 12px;
}

.input-info_icon__wrapper img {
  padding-bottom: 5px;
}

.input-info_text {
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 151.5%;
  color: #8c8c8c;
  margin-left: 11.42px;
  width: 80%;
}

.cstm-react__select__control,
.cstm-react__select__control--is-focused {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}

.cstm-react__select__wrapper {
  background-color: transparent !important;
  border: 1px solid #8c8c8c !important;
  /* backdrop-filter: blur(4px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px !important;
}

.cstm-react__select__wrapper.error_detected {
  border: 1px solid #dc3545 !important;
}

.cstm-react__select__input-container input:focus {
  border: none !important;
}

.cstm-react__select__indicator.cstm-react__select__dropdown-indicator svg {
  display: none;
}

.cstm-react__select__indicator.cstm-react__select__dropdown-indicator {
  background-image: url("../img/headerselect.svg");
  background-repeat: no-repeat;
  margin-top: 15px;
}

.cstm-react__select__indicator-separator {
  display: none !important;
}

.cstm-react__select__input-container input {
  padding: 0px !important;
}

.cstm-react__select__input-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.upload-content__image__wrapper {
  border-top: 1px dashed #656565;
  padding: 55px 0px;
}

.rss-upload-button__wrapper {
  padding: 33px 0px 22px 0px;
  border-top: 1px solid #656565;
}

.no-border {
  border-top: none !important;
  border-bottom: none !important;
}

.rss-upload-button__wrapper .btn {
  width: 340px !important;
  padding: 15px 0px !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 500 !important;
  color: #ffffff;
}

.no-cover_art__text {
  font-weight: 400;
  font-size: 13px;
  line-height: 151.5%;
  /* identical to box height, or 20px */

  color: #8e75ff;
  margin-left: 11px;
}

.no-cover_art__wrapper .cstm-checkbox__input {
  /* -webkit-appearance: none;
    appearance: none;
    background-color: #fff !important; */
  margin: 0;
  width: 12px !important;
  height: 12px !important;
  border-radius: 1px !important;
  padding: unset !important;
  cursor: pointer;
  accent-color: #8e75ff !important;
}

.cstm-checkbox__input {
  accent-color: #8e75ff !important;
}

.uploaded-rss__details__img {
  width: 35px;
  height: 35px;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
}

.uploaded-rss__details__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */
  color: #ffffff;
  margin-left: 18px;
}

.uploaded-rss__details__wrapper {
  padding-top: 15px;
  /* margin: 30px 0px; */
}

.uploaded-rss_details {
  padding: 30px 0px;
  border-bottom: 1px dashed #656565;
}

.uploaded-rss_details.no-border,
.uploaded-rss_details:last-child {
  border-bottom: none;
}

.rss-player__container {
  background: #3a3a3a;
  backdrop-filter: blur(4px);
  border-radius: 5px;
  padding: 4px 30px 4px 10px;
  height: 54px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 7px;
  margin-left: 10px;
}

.upload-rss_details__container {
  border-bottom: 1px solid #656565;
}

.copyright__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;
  margin: 15px 0px 20px 0px;
}

.fill__container {
  height: 200px !important;
  /* border-bottom: 1px solid #656565 !important; */
}

.modal-content {
  background-color: unset !important;
}

.analytics-card .upload-success__modal {
}

.upload-success__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;
  margin: 20px 0px;
}

.upload-success__sub-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 148%;
  /* or 19px */

  text-align: center;

  color: #c4c4c4;
  width: 78%;
  margin: auto;
}

.upload-succes__icon__container {
  margin-top: 30px;
}

.upload-success__button {
  margin: 30px 0px 20px 0px;
}

.fill-chapter-container {
  height: 130px;
}

.rss-upload-button__wrapper .add-chapter__btn {
  width: 162px !important;
  padding: 13px 0 !important;
  margin-bottom: 37px !important;
}

.upload-mp3__btn {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 15px !important;
  color: #ffffff !important;
  background: #8e75ff !important;
  border-radius: 5px !important;
  padding: 15.6px 20px !important;
  margin-left: 10px !important;
  margin-top: 8px;
}

.delete-mp3__icon__wrapper {
  margin-bottom: 11px;
}

.delete-mp3__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #8c8c8c;
}

.content-action__cta__wrapper {
  position: absolute;
  width: 108px;
  height: 95px;
  top: 170%;
  left: 12%;
  background: #444444;
  border: 1.5px solid #444444;
  border-radius: 11px;
  z-index: 99;
  padding: 23px 24px;
  backdrop-filter: blur(14px);
}

.content-action__cta__wrapper::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5.8px;
  border-width: 10px;
  border-style: solid;
  border-color: #444444 transparent transparent transparent;
  transform: rotate(180deg);
}

.content-action__icon {
  margin-right: 13px;
}

.content-action_edit_text,
.content-action_delete_text {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.content-action__cta {
  margin-bottom: 15px;
  cursor: pointer;
}

.content-action_delete_text {
  color: #ff6c6c;
}

.cancel-btn {
  background: #484848 !important;
  border-radius: 100px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 15px !important;
  text-align: center !important;
  color: #ffffff !important;
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  margin-right: 18px;
}

.selected-content__text {
  color: #8e75ff;
}

.delete_content__confirmation_btn {
  /* gap: 18px; */
}

.content-filter__ctrl__wrapper {
  margin-top: 23px !important;
}

.filter-ctrl__wrapper {
  margin-right: 14px;
  padding: 4px 15px !important;
}

.filter-ctrl__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.03em;

  color: #8c8c8c;
}

.filter-type_text {
  margin-right: 18px;
}

.filter-content-action__cta__wrapper {
  position: absolute;
  min-width: 92%;
  min-height: 122px !important;
  top: 140%;
  left: -2%;
  background: rgba(217, 217, 217, 0.19);
  backdrop-filter: blur(14px);
  border-radius: 11px;
  z-index: 99;
  padding: 23px 24px;
  backdrop-filter: blur(14px);
}

.genre_section {
  width: 190px !important;
  min-height: 389px !important;
  height: unset !important;
  padding: 23px 17px !important;
}

.paywall_section {
  min-height: 95px !important;
}

.active-filter__genre {
  /* right: -60% !important; */
  padding: 23px 17px !important;
  min-width: 192px !important;
}

.select_lg.active-filter__genre {
  /* right: -60% !important; */
  left: 30% !important;
}

.select_sm.active-filter__genre {
  /* right: -60% !important; */
  left: 48% !important;
}

.language-section {
  right: unset !important;
  left: 0% !important;
  padding: 23px 17px !important;
  min-width: 200px;
}

.language-select_section {
  min-width: 135px;
  margin-right: 0 !important;
}

.genre-select_section {
  min-width: 120px;
}

.filter-content-action__cta__wrapper.genre_section::after {
  right: 46%;
}

.filter-content-action__cta__wrapper.active-filter__genre::after {
  right: 80%;
}

.filter-content-action__cta__wrapper::after,
.report_date__filter-wrapper::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 10px;
  margin-left: -5.8px;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(217, 217, 217, 0.19) transparent transparent transparent;
  transform: rotate(180deg);
}

.filter-select__option {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 17px !important;
  cursor: pointer;
}

.filter-select__option:last-child {
  margin-bottom: 0px !important;
}

.filter__checkbox {
  margin-right: 11px;
}

.rss-type__select .cstm-react__select__input {
  padding: 0 !important;
}

.cstm-react__select__menu {
  z-index: 999;
  background: rgba(217, 217, 217, 0.19) !important;
  backdrop-filter: blur(30px) !important;
  color: #fff;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cstm-react__select__option,
.cstm-react__select__option__menu-list {
  cursor: pointer !important;
}

.cstm-react__select__single-value {
  color: white !important;
}

.cstm-react__select--is-disabled .cstm-react__select__multi-value__label,
.cstm-react__select--is-disabled .cstm-react__select__multi-value__remove,
.cstm-react__select__single-value--is-disabled {
  color: #8c8c8c !important;
}

.cstm-react__select__multi-value__label,
.cstm-react__select__multi-value__remove {
  color: #fff !important;
}

.cstm-react__select__multi-value {
  background-color: transparent !important;
}

.mp3-upload-wrapper_w-50 {
  width: 50%;
}

.hours-listened__filter-wrapper {
  min-width: 170px;
}

.report_date__filter-wrapper {
  right: unset !important;
}

.report_date__filter-wrapper::after {
  right: 80% !important;
  bottom: unset !important;
  top: calc(0% - 21.4px);
}

@media (max-width: 969px) {
  .search-content__wrapper {
    width: unset !important;
  }

  .filter-icon__wrapper {
    height: 45px !important;
  }

  .content-select__text {
    margin-right: unset !important;
  }

  .content-select__text.mb-0 {
    margin-bottom: 8px !important;
  }

  .rss-upload-button__wrapper .btn {
    width: 100% !important;
  }

  .side-padding {
    width: 100%;
  }

  .upload-content__form.rss-input__form .form_container {
    padding-bottom: 15px !important;
  }

  .mp3-upload-wrapper_w-50 {
    width: 100%;
  }

  .mp3-upload-wrapper_w-50 .w-25,
  .mp3-upload-wrapper_w-50 .w-75 {
    width: 100% !important;
  }

  .mp3-upload-wrapper_w-50 .upload-mp3__btn,
  .rss-player__container {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }

  .rss-player__container {
    margin-bottom: 8px !important;
  }

  .content-table.withdraw {
    padding: 10px 15px 40px 15px !important;
  }

  .download-report_img__wrapper {
    height: 45px !important;
    flex-shrink: 0;
  }

  .report-mobile_search {
    padding: 6px 2px 8px 10px !important;
  }

  .report-mobile-filter_wrapper,
  .mobile-active-filtering {
    padding: 0px 15px 0px 10px !important;
    height: 47px !important;
  }

  .mobile-active-filtering {
    height: 50px !important;
  }

  .filter-ctrl__wrapper {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .content-details.content-status {
    width: 35% !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
