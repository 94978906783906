.genti-app_wrapper,
header {
    background-color: #121212;
}

.genti-app_wrapper {
    min-height: 100vh;
    font-family: 'Graphik', sans-serif !important;
}

header {
    top: 0;
    border-bottom: 1px solid #2F2F2F;
    right: 0;
    width: 80%;
    z-index: 99;

}

.header-right-wrapper,
.header-logo-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
}

.header-logo-wrapper {
    padding-top: 15.5px;
}

.header-left-container {
    width: 100%;
    border-bottom: 1px solid #2F2F2F;
}

.header-right-wrapper {
    gap: 14px
}

/* 
.header-select {

 

} */

.header-select,
.header-select:focus {
    padding: 8px 45px 8px 23px;
    border: 1.5px solid #444444;
    border-radius: 71px !important;
    background: url('../img/headerselect.svg') no-repeat right rgba(217, 217, 217, 0.19);
    border: none;
    box-shadow: none !important;
    appearance: none;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    cursor: pointer;
    padding-left: 0px !important;
    padding-left: 18px !important;
    background-position: 91px;
}

.header-notification-wrapper {
    width: 36px;
    height: 36px;
    background: rgba(217, 217, 217, 0.19);
    border: 1.5px solid #444444;
    border-radius: 50%;
}

.header-user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.03em;

    color: #FFFFFF;
}

.header-user-email {
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;

    color: #8C8C8C;
}

.user-details {
    margin-left: 8px;
    margin-right: 15px;
}

.genti-sidebar {
    width: 20%;
    left: 0;
    border-right: 1px solid #2F2F2F;
    height: 100vh;
}

.genti-main {
    padding-top: 90px;
    width: 80%;
    margin-left: 20%;
}

.genti-main-container {
    padding: 23px 40px;
}

.sidebar-menu-wrapper {
    margin-top: 33px;
    padding: 0 14px;
}

.menu-link {
    text-decoration: none;
}

.menu-label {
    margin-left: 14.5px !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #8C8C8C;
}

a.menu-link.active>div {
    text-decoration: none !important;
    background-image: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%) !important;
    border-radius: 5px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.menu-link.active .menu-label {
    color: white !important;

}

.listener-count {
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    color: #FFFFFF;
}

.page-details {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
}

.user-initials__wrapper {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    background: #383838;
    border: 1.5px solid #8E75FF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

@media (max-width:992px) {
    .genti-main {
        padding-top: 0px !important;
        width: 100% !important;
        margin-left: 0px !important;
    }

    .genti-main-container {
        padding: 103px 20px 23px 20px;
    }

    .mobile-header {
        width: 100% !important;
        background: #060606 !important;
        background-blend-mode: hard-light !important;
        backdrop-filter: blur(5px) !important;
    }

    .mobile-logo__wrapper img {
        width: 58px;
        height: 35px;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .mobile-side__bar__wrapper {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background: #191919ba;
        overflow-x: hidden;
        transition: 0.5s;
        z-index: 9999;
        transition: 0.5s;
    }

    /* .mobile-side__bar {
        background: #121212 !important;
        width: 75%;
        height: 100%;


    } */

    /* .mobile-side__bar div {
        display: none;
    } */

    .mobile-side__bar__wrapper.show .mobile-side__bar {
        width: 75%;
        padding: 60px 16px !important;
        height: 100%;
        background: #121212 !important;
        display: block !important;

    }

    .mobile-side__bar__wrapper.show .mobile-side__bar>div:not(.d-flex) {
        width: 100%;
        display: block !important;


    }

    .mobile-side__bar__wrapper .mobile-side__bar,
    .mobile-side__bar__wrapper .mobile-side__bar>div:not(.d-flex) {
        width: 0%;
        display: none !important;


    }

    .mobile-side__bar__wrapper.show {
        width: 100%;
        /* padding: 60px 16px !important; */
    }

    .mobile-side__bar.show div {
        display: block;

    }

    .unauthorized-wrapper {
        border-bottom: none;
        padding-bottom: 0px !important;
    }

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.logout-section {
    bottom: 0;
    left: 22px;
}


.section-container {
    border-bottom: 5px solid #141414;
    /* height: 80vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
  }