body {
  margin: 0;
  font-family: Axiforma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212 !important;
  padding: 0;
}

.dark-header {
  background: #121212 !important;
}

/* * {
  font-family: Axiforma;
} */

html {
  overflow: scroll;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  /* width: 5px; */
}

:root {
  --primary-color-gradient: linear-gradient(87deg,
      #5f42e2 11.49%,
      #9b42c0 131%);
  --secondary-color-gradient: linear-gradient(87deg,
      #9b42c0 11.49%,
      #5f42e2 131%);
}

.auth-layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: Axiforma !important;
}

.auth-bg-img,
.bg-img {
  height: 100%;
  width: 100;
}

.bg-img {
  object-fit: cover;
}

.auth-layout:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(179.74deg, rgba(18, 18, 18, 0.59) -28.03%, rgba(16, 16, 16, 0.98) 71.57%);
  height: 100vh;
}

.auth-content-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form {
  /* background: rgba(18, 18, 18, 0.82);
  backdrop-filter: blur(6px);
  position: relative;
 
  border-radius: 28px;

  padding: 24px 41px;
  border-right: 6px solid;
  border-bottom: 6px solid;
  border-left: 6px solid;
  border-top: 6px solid;
  width: 30.69%;
  border-image: linear-gradient(30deg, rgba(18, 18, 18, 0.82) 2%, #8C8C8C, rgba(0, 0, 0, 0)) 1;
  clip-path: inset(5px round 11px); */
  background: rgba(18, 18, 18, 0.82);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  position: relative;
  border-radius: 28px;
  /* width: 422px; */
  padding: 24px 41px;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-top: 1px solid;
  width: 30.69%;
  /* border-image: linear-gradient(30deg, rgba(18, 18, 18, 0.82) 2%, #8C8C8C, rgba(0, 0, 0, 0)) 1; */
  /* clip-path: inset(5px round 11px);*/
}




/* .auth-form::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  height: 20px;
  width: 20px;
  border-bottom: 1px solid #8C8C8C;
  border-bottom-right-radius: 28px;
}

.auth-form::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border-top: 1px solid #8C8C8C;
  border-top-left-radius: 28px;
} */




.form-title {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.03em;

  color: #FFFFFF;
}

.logo-wrapper {
  margin-bottom: 16.62px;
}

.form-wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-label {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height */

  /* text-align: center; */

  color: #FFFFFF;
}

.phone-number-wrapper {
  border: 1px solid #8C8C8C !important;
  border-radius: 5px !important;
  padding: 1px;
  background: #262626 !important;

}

.phone-number-wrapper select:focus,
.phone-number-wrapper input:focus {
  border: none !important;
}

select {
  position: relative;
  background-image: url('./assets/img/select.svg');
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;

  background-color: #262626;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  cursor: pointer;
  border: none;
  /* padding-left: 13px !important; */

  color: white;
}

.phone-number-wrapper .select-input-wrapper {

  border-right: 1px solid #8C8C8C !important;

}

.phone-number-wrapper input {
  position: relative;

}

.phone-number-wrapper .auth-input {
  border: none !important;
  border-radius: 0px !important;
  position: relative;
}

.phone-number-wrapper span {
  position: relative;
}

.phone-number-wrapper span::before {
  content: '';
  position: absolute;
  background-color: #8C8C8C;
  left: 5px;
  top: 12px;
  width: 1px;
  height: 29px;
  z-index: 9;
}


.auth-input,
.otp-input {
  background: #262626 !important;
  border: 1px solid #8C8C8C !important;
  backdrop-filter: blur(4px) !important;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px !important;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF !important;
  transition: border ease-in-out 100ms !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  padding-left: 20px !important;


}

.auth-input:-webkit-autofill,
.auth-input:-webkit-autofill:hover,
.auth-input:-webkit-autofill:focus,
.auth-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262626 inset !important;
  -webkit-text-fill-color: #fff !important;

}

textarea {
  resize: none !important;
}

textarea:focus,
input:focus,
.auth-input:focus,
.password-input-wrapper:focus,
.otp-input:focus,
select:focus,
.phone-number-wrapper:focus-within,
.react-international-phone-input-container:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #C084FF !important;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
  /* backdrop-filter: blur(4px) !important; */
  border-radius: 5px !important;
  background: transparent !important;

}

.password-input-wrapper .auth-input,
.password-input-wrapper .auth-input:focus {
  border: none !important;
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.visibility-icon {
  text-align: center;
  color: #FFFFFF;
  background: #373737;
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  right: 10px;
  top: 25%;
}

.otp-input {
  width: 50px;
  height: 50px;
  left: 833px;
  top: 274px;

  background: #262626;
  border: 1px solid #8C8C8C;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
}

.otp-verify-wrapper {
  margin-top: 68px;
  margin-bottom: 60px;

}

.auth-link,
.auth-link:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.03em;

  color: #8642CC;
  text-decoration: none;
}

.forgot-password-link,
.sign-in-btn {
  margin-bottom: 34px;
}

.sign-text {
  margin-bottom: 43px;
}

.auth-btn {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%) !important;
  border-radius: 100px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-align: center !important;

  color: #FFFFFF !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;

  transition: background 0.5s ease-in !important;
}

.auth-btn:hover {
  background: linear-gradient(86.63deg, #9B42C0 11.49%, #5F42E2 131%) !important;

}

.auth-btn:disabled {
  opacity: 0.6 !important;
  border: none;

}

.auth-text {
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;
}

.create-text {
  margin-bottom: 29px;
}

.otp-text,
.verify-email {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #CECECE;
}

.verify-email {
  color: #D5ACFF;
}

.resend-otp-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #C4C4C4;
}

.resend-countdown {
  color: #FFF;
}

.back-icon {
  width: 33px;
  height: 33px;
  top: 20px;
  left: 20px;
  background: #262626;
  backdrop-filter: blur(2.69388px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 9px;
}

.congrats-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}

.congrats-sub-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #C4C4C4;
  width: 75%;
  margin: 0 auto 48px auto;
}

.animation-wrapper {
  margin-top: 50.2px;
  margin-bottom: 20px
}

.password-reset-email {
  margin-top: 58px;
  margin-bottom: 58px
}

.footer-section {
  background: #151515da;
  color: white;
}

.footer-header_text {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.5%;
  letter-spacing: 0.36px;
}

.copyright_container {
  border-top: 1px solid #404141;
}


.footer-link__wrapper,
.footer-link__wrapper li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-link__wrapper li,
.footer-link__wrapper li a,
.footer-link__wrapper .footer-link,
.footer-link__wrapper a {
  color: #ababab;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.5%;
  letter-spacing: 0.32px;
  margin-bottom: 12px !important;
  cursor: pointer;
  text-decoration: none;
}

.footer-link__wrapper li:hover,
.footer-link__wrapper a:hover,
.footer-link:hover {
  color: #fff !important;
}

.header-wrapper {
  padding: 22px 0 36px 0;
  background: linear-gradient(180deg,
      rgba(4, 4, 4, 0) 10.09%,
      rgba(17, 17, 17, 0) 10.21%);
  z-index: 999;
}

.genti-gradient_btn {
  color: var(--text-color-white) !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  padding: 16px 30px !important;
  border-radius: 50px !important;
  background: var(--primary-color-gradient);
  transition: all 0.5s ease-in-out;
}

.genti-gradient_btn:hover {
  background: var(--secondary-color-gradient);
}

.faq-container{
  width:60%
}
@media (max-width:768px) {
  .auth-form {
    width: 85%;
    padding: 24px 30px;
  }

  .otp-input {
    width: 42px;
    height: 42px;
  }
  .faq-container{
    width:90%
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .auth-form {
    width: 65%;
    padding: 24px 35px;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .auth-form {
    width: 50%;
    padding: 24px 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .auth-form {
    width: 40%;
    padding: 24px 40px;
  }
}

.react-international-phone-input-container .react-international-phone-input {
  width: 100%
}

.react-international-phone-input:focus {
  border: none !important;
}

.react-international-phone-country-selector-button,
.react-international-phone-input {
  padding: 1.69rem 0.9rem !important;
}

.react-international-phone-country-selector-button:focus,
.react-international-phone-input:focus {
  border: none !important;
  background: #121212;
}

.react-international-phone-input-container:focus-within .react-international-phone-country-selector-button {
  background: #121212;

  border-right: 1px solid #C084FF !important;
}

.section-1-landing-page {
  background-color: transparent;
  background: radial-gradient(
    circle at bottom right,  
    #9b6bc0 10%,   /* Light purple/whitish at the bottom right */
    #43125a 40%,   /* Rich purple transition */
    #0a0216 80%    /* Deep dark color at the top */
  ),
  conic-gradient(
    from 200deg at 70% 40%, 
    #0a0216, 
    #43125a, 
    #9b6bc0
  );
  width: 100%;
  height: 700px;
}

.landing-page-sect1-bg {
  max-width: 100%;
  max-height: 0;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}

.landing-page-sect1-bg img {
  width: 700px;
  height: 700px;
  object-fit: cover;
}

.sect1-landing-content {
  padding: 2% 5%;
}

.landing-nav {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.landing-nav button {
  border: none;
  border-radius: 30px;
  padding: 2px 25px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 600;
}

.landing-nav button span {
  font-size: 20px;
  margin-top: -2px;
}

.landing-nav button:hover {
  font-size: 15px;
  padding: 1px 25px;
}

.landing-sect1-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding: 2rem 0;
}

.landing-sect1-main .top-button {
  background-color: #413E59;
  border-radius: 1rem;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 12px;
}

.landing-sect1-main .text-section {
  width: 550px;
  letter-spacing: 0.04rem;
}

.landing-sect1-main .text-section .title {
  font-weight: 600;
  font-size: 50px;
  letter-spacing: unset;
}

.landing-sect1-main .bottom-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.landing-sect1-main .bottom-buttons .button1 {
  background-color: white;
  border-radius: 2rem;
  border: none;
  padding: 1rem 1.5rem;
  font-weight: 600;
}

.landing-sect1-main .bottom-buttons .button1:hover {
  background-color: transparent;
  background: linear-gradient(to right, #5B49EF, #5f42e2);
  color: white;
}

.landing-sect1-main .bottom-buttons .button2 {
  background-color: transparent;
  color: white;
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  font-weight: 600;
  border: 1px solid pink;
}

.landing-sect1-main .bottom-buttons .button2:hover {
  background-color: pink;
  color: #0a0216;
}

.section-2-landing-page {
  background-color: white;
  padding: 2% 5%;
}

.landing-page-sect2-head {
  font-size: 25px;
  width: fit-content;
  margin: auto;
  font-weight: 600;
}

.landing-page-sect-2-cards {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.card-gradient-border {
  display: inline-block;
  position: relative;
  border-radius: 16px;
  background: linear-gradient(to bottom right, #5B49EF, #FFFFFF, #FFFEFF, #8842CA);
  padding: 1px;
  width: 385px;
}

.card-content {
  position: relative;
  background: #fff;
  border-radius: 14px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  height: 293px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.card-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 14px;
  background-image:
  linear-gradient(#ddd 1px, transparent 1px),
  linear-gradient(90deg, #ddd 1px, transparent 1px);
  background-size: 32px 32px;
  opacity: 0.3;
  pointer-events: none;
  z-index: 1;
}

.card-content div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.3rem;
}

.card-content h3 {
  font-size: 19px;
  font-weight: 600;
}

.card-content p {
  font-size: 15px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
}

.landing-page-sect2-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8rem;
}

.landing-sect-2-end-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  /* align-items: center; */
  /* max-width: 600px; */
}

.landing-sect-2-end-content h2 {
  font-size: 32px;
  font-weight:700;
  max-width: 400px;
}

.sect2-end-flex-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0;
}

.sect2-end-flex-section h4 {
  max-width: 140px;
  font-size: 19px;
  font-weight: 600;
}

.landing-sect-2-end-content button {
  border: none;
  border-radius: 1.9rem;
  background-color: transparent;
  background: linear-gradient(to right, #8F78F4, #8842CA);
  color: white;
  padding: 1rem 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 1rem;
}

.landing-sect-2-end-content button:hover {
  background-color: transparent;
  background: linear-gradient(to right, #8842CA, #8F78F4);
}

.section-3-landing-page {
  background-color: black;
  padding: 2% 5%;
}

.landing-page-sect3-head {
  color: white;
  font-size: 25px;
  width: fit-content;
  margin: auto;
  font-weight: 600;
}

.landing-sect-3-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.sect-3-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 30%;
  text-align: center;
  padding: 2rem 1rem;
}

.sect-3-card p {
  color: #A5ABB6;
}

.landing-page-section4 {
  background-color: white;
  padding: 2% 5%;
}

.absolute-head-phone {
  position: relative;
  top: 0;
  left: 0;
}

.landing-page-sect4-head {
  font-size: 25px;
  width: fit-content;
  margin: auto;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.landing-page-sect4-head h2 {
  margin-left: 40%;
}

.landing-page-sect4-head .carousel-controls {
  justify-self: flex-end;
}

.landing-page-sect4-head .carousel-controls button {
  /* right: 1.2vw;
  top: 0; */
  font-size: 10px;
  border-radius: 25px;
  padding: 8px 20px;    

}

.sect4-testimony-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 2rem auto;
  width: 1400px;
}

.sect4-testimony-card {
  background-color: #F7F7F7;
  width: 400px;
  padding: 1.5rem 2rem;
  border-radius: 1.2rem;
  font-size: 15px;
}

.sect4-testimony-card-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.sect4-testimony-card-bottom p {
  margin-bottom: 0;
}

.sect4-testimony-card-bottom .testimony-card-name {
  font-weight: 700;
}

.sect4-support-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #5B49EF;
  border-radius: 2rem;
  padding: 3.5% 5%;
  color: white;
}

.sect4-support-section .support-left-section {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 60%;
}

.sect4-support-section .support-left-section .support-request {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
}

.sect4-support-section .support-left-section .support-request h1 {
  font-size: 55px;
}

.sect4-support-section .support-left-section .support-request p {
  columns: #cac7c7;
}

.sect4-support-section .support-left-section:first-child div h6 {
  color: #cac7c7;
}

.sect4-support-section .support-left-section .social-links {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.landing-page-footer {
  background-color: black;
  padding: 4% 5%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.landing-page-footer-left {
  width: 50%;
}

.landing-page-footer-left .footer-left-content {
  max-width: 65%;
}

.landing-page-footer-right {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
}

.landing-page-footer-right div {
  padding: 0.7rem 0;
}

.landing-page-footer-download div {
  display: flex;
  gap: 0.5rem;
}