.page-title {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;

  color: #8c8c8c;
  margin-bottom: 14px;
}

.dashboard-greeting {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;

  color: #ffffff;
  margin-bottom: 15px;
}

.greeting-details {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: #8c8c8c;
}

/* .bg-gradient {

} */

.analytics-card {
  position: relative;
  border-radius: 0.75rem;
  border-radius: 28px;
  min-height: 157px;
  background: linear-gradient(
    30deg,
    #121212 3%,
    #8c8c8c 70%,
    #121212 100%,
    #8c8c8c 20%,
    #8c8c8c 70%
  );
  border-radius: 28px;
  padding: 25px 29px;
  backdrop-filter: blur(6px);
}

.analytics-card.dashboard-card {
  height: 157px;
}
.main-dashboard_section .analytics-card.dashboard-card {
  min-height: 195px;
}

.analytics-card.withdraw {
  padding: 25px 29px 12px 29px !important;
}

.analytics-card::after {
  content: "";
  position: absolute;
  background-color: #121212;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  z-index: -1;
  border-radius: 28px;
}

.analytics-card.withdraw::after {
  /* background-color: #121212; */
  background: #262626 !important;
}

.analytics-card_details {
  z-index: 99999;
}

.analytics-card_title {
  font-weight: 700;
  font-size: 16px;
  /* line-height: 18px; */
  line-height: 138%;
  /* identical to box height */
  color: #ffffff;
}

.dashboard-withdraw_btn {
  background: linear-gradient(
    89.66deg,
    #5f42e2 0.29%,
    #8942ca 99.75%
  ) !important;
  border-radius: 16px !important;
  padding: 7px 20px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 13px;
  text-align: right;

  color: #ffffff !important;
  transition: all ease-in 5s;
}

.dashboard-withdraw_btn:hover {
  background: linear-gradient(
    89.66deg,
    #8942ca 0.29%,
    #5f42e2 99.75%
  ) !important;
}

.analytics-card_value {
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height */
  /* text-wrap: wrap; */
  white-space: normal;
  overflow-wrap: break-word;

  color: #ffffff;
  /* margin-top: 34px; */
}

.analytics-cta {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  text-align: right;

  color: #8e75ff;
}

.second-row.analytics-card {
  height: 420px !important;
}

.third-row {
  height: 367px !important;
}

.top-releases_wrapper {
  border-bottom: 1px solid #363636;
  padding-bottom: 35px;
}

.release-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #8c8c8c;
  margin-right: 14px;
}

.release-details {
  margin-left: 23px;
}

.release-img_wrapper img {
  /* width: 100%;
    height: 100%; */
  width: 41px;
  height: 41px;
  border-radius: 1px;
  object-fit: cover;
}

.top-releases_stats {
  padding: 12px 0;
  border-bottom: 1px solid #363636;
}

.release-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 10px;
}

.stat_value {
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;

  color: #8c8c8c;
  margin-right: 28px;
}

.chart-wrapper .apexcharts-canvas {
  background-image: linear-gradient(
      179.74deg,
      rgba(18, 18, 18, 0.59) -28.03%,
      rgba(16, 16, 16, 0.78) 71.57%
    ),
    url("../img/chartbg2.png"), url("../img/chart.png");
  backdrop-filter: blur(6px);
  background-repeat: no-repeat;
  background-position: center, left, right;
  /* background-size: 100% 100%; */
  font-family: "Graphik", sans-serif !important;
}

.stock_percentage {
  color: #57f2a8;
}

.listener-count {
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height */
  margin-right: 10px;

  color: #ffffff;
}

.listener-details-stats {
  margin-bottom: 17px;
}

.listener-flag_wrapper {
}

.listener-flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8c8c8c;
  /* padding: 1px; */
  margin-right: 9px;
  object-fit: cover;
}

.listener-location {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: #ffffff;
}

.listener-percentage {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: #ffffff;
}

.transaction-table th {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;

  color: #ffffff;
  padding-bottom: 22px !important;
  padding-left: 0 !important;
  border-color: #363636;
}

.transaction-table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: #8c8c8c;
  padding: 20px 0 26px 0 !important;
  border-color: #363636;
}

.user-progres-wrapper {
  margin-top: 36px;
  height: 270px;
}

.circular_progress_bar {
  width: 200px;
  height: 200px;
}

.filter-wrapper {
  background: #8e75ff;
  border: 1.5px solid #444444;
  border-radius: 5px;
  color: #ffffff;
  padding: 0px 19px !important;
  height: 36px;
}

.filter-wrapper:hover {
  background: #6c55d5;
}

.filter_text {
  font-weight: 400;
  font-size: 17px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-right: 9.75px;
}

.calendar-input_wrapper {
  /* height: 430px; */
  top: 55px;
  right: 0;

  background: rgba(217, 217, 217, 0.19);
  border: 1.5px solid #444444;
  backdrop-filter: blur(14px);
  border-radius: 11px;
  /* width: 328px; */
  z-index: 999;
  padding: 23px 24px 32px 24px;
}

.start-date-picker {
  margin-right: 14px;
}

.picker-label {
  font-size: 13px !important;
  line-height: 14px !important;
  letter-spacing: -0.03em !important;
  color: #ffffff !important;
  margin-bottom: 9px !important;
}

.start-date-picker .react-datepicker__input-container input {
  padding-right: 0px;
}

.end-date-picker .react-datepicker__input-container input {
  padding-right: 10px;
}

.react-datepicker__input-container input {
  width: 100% !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 11px;
  border: 1.5px solid #ffffff;
  /* backdrop-filter: blur(14px); */
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  background: transparent;
  color: white;
}

.react-datepicker__input-container input:hover,
.react-datepicker__input-container input:focus,
.react-datepicker__input-container input:active {
  border: 1.5px solid #8e75ff !important;
  box-shadow: none !important;
  outline: none;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 17px;
}

.react-datepicker {
  /* font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 0.3rem; */
  font-family: "Inter", sans-serif;
  display: inline-block;
  position: relative;
  background: #121212;
  box-shadow: 0px 4.64745px 13.9424px rgba(0, 0, 0, 0.06);
  border-radius: 13px;
  color: #fff !important;
  border: none;
}

.react-datepicker__header {
  background: #121212;
  color: #fff !important;
  border-bottom: none;
}

.react-datepicker__current-month {
  font-weight: 600;
  /* font-size: 22.2661px !important; */
  line-height: 20px;
  /* identical to box height */

  color: #e4e4e4;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 13px !important;
  border-top-left-radius: 13px !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-weight: 500;
  font-size: 13.9424px;
  /* identical to box height */
  color: #e4e4e4;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background: #8e75ff;
  border-radius: 0;
}

.react-datepicker__month {
  margin-top: 0px !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 17px;
  position: absolute;
  inset: 0px 0px auto auto;
  /* transform: translate3d(33.5px, 102.5px, 0px) !important; */
}

.react-datepicker__current-month {
  text-align: left;
  padding-left: 13px;
  font-weight: 600;
  font-size: 19.2661px !important;
  margin-bottom: 13px;
  margin-top: 13px;
}

.react-datepicker__navigation--previous {
  right: 38px;
  left: unset;
  top: 22px;
}

.react-datepicker__navigation-icon::before {
  border-color: #3b7be0 !important;
  top: 12px;
  border-style: solid !important;
  border-width: 1px 1px 0 0 !important;
  content: "" !important;
  display: block !important;
  height: 6px !important;
  position: absolute !important;
  width: 6px !important;
}

.react-datepicker__navigation-icon--previous::before {
  right: -2px;
}

.react-datepicker__navigation-icon--next {
  left: 0px;
}

.react-datepicker__navigation {
  top: 23px;
}

.react-datepicker__navigation--next {
  right: 12px;
}

.react-datepicker__navigation-icon--next::before {
  left: -4px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  width: 18.59px;
  height: 18.59px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cts-popper {
  width: 85%;
  margin: 0 auto;
  background: red;
}

@media (max-width: 420px) {
  .circular_progress_bar {
    /* width: unset !important; */
    height: unset !important;
  }
}
