.content-action__cta__wrapper.report-action__wrapper {
    width: 153px !important;
    height: 63px !important;
    top: 170% !important;
    right: -42px !important;
    left: unset;
}

.report-action__wrapper.content-action__cta__wrapper::after {
    left: 50%;
}

.report-details-wrapper .second-row.analytics-card {
    /* min-height: 237px; */
    height: unset !important;
    padding: 25px 34px 40px 26px !important;
}

.report-details__img {
    width: 165px;
    height: 165px;
    border-radius: 13.75px;
    margin-right: 37px;
}

.report-content_details__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 19px;
}

.report-content_details__episode-count,
.report-details_author_name {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 17px;
    color: #8C8C8C;
}

.report-details_author_name {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.report-details_author_img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.report-details_author_wrapper {
    margin-bottom: 27px;
}

.report-details_stats__wrapper {
    background: #383838;
    border: 1.5px solid #444444;
    border-radius: 5px;
    padding: 14px 24px;
    width: 100%;
}

.report-details_stat_title {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #8C8C8C;
}

.report-details_stats__container {
    padding: 0 23px;
    border-right: 1px dashed #656565;
}

.report-details_stats__container.first_stat {
    padding-left: 0px !important;
}

.report-details_stats__container.last_stat {
    border-right: none
}

.report-details_stat_img__wrapper {
    margin-right: 12px;
}

.report-details_episode__img {
    margin-right: 16px;
}

.download-report_img__wrapper {
    width: 40px;
    height: 36px;
    background: #383838;
    border: 1.5px solid #444444;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.user-initials__wrapper.report-details_author_img {
    font-size: 10px !important;
}

.report-mobile-tile {
    background: linear-gradient(30deg, #121212 3%, #8C8C8C 70%, #121212 100%, #8C8C8C 20%, #8C8C8C 70%);
    padding: 16px;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    position: relative;
    margin-bottom: 20px;
}

.report-mobile-tile::after {
    content: "";
    position: absolute;
    background-color: #262626;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    z-index: -1;
}

.report-mobile-tile_header {
    border-bottom: #656565 1px solid;
    padding-bottom: 16px;
}

.report-mobile-tile .report-name,
.report-mobile-tile .tile-author {
    color: #AEAEAE;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 11px;

}

.report-mobile-tile .tile-author {
    text-align: right;
}

.report-mobile-tile .tile-key,
.report-mobile-tile .tile-value,
.tile-details__key,
.tile-details__value {
    color: #8C8C8C;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.report-mobile-tile .tile-value,
.tile-details__value {
    color: #AEAEAE;
    font-weight: 500;
}

.report-tile__img {
    width: 36px;
    height: 36px;
    border-radius: 5px;
}

.tile-details__wrapper {
    padding-top: 16px;
}

.tile-details__key,
.tile-details__value {
    font-size: 12px;
}

.tile-key-value_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.tile-key-value_wrapper:last-child {
    margin-bottom: 0px !important;

}

@media(max-width: 767px) {
    .reports-card_wrapper .content-table.withdraw {
        padding: 0 !important;
        background: #262626;
    }

    .reports-card_wrapper .table-details__wrapper {
        margin-bottom: 24px;
        padding: 20px 16px 0px;
    }

    .reports-card_wrapper .analytics-card.withdraw::after {
        background: #121212 !important;
    }

    .content-filter__ctrl__wrapper {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }

    .report-details-wrapper .second-row.analytics-card {
        padding: 16px !important;
    }

    /* background-color: #121212; */
    .report-details__img {
        width: 120px;
        height: 120px;
        margin-right: 12px;
    }

    .report-content_details__title {
        font-size: 16px;
    }

    .report-details_author_wrapper {
        margin-bottom: 10px;
    }

    .report-details_stat_title {
        font-size: 14px;
    }

    .report-details_stats__wrapper {
        padding: 17px 12px;
    }

    .report-details_stats__container {
        padding: 0px 13px;
    }

    .report-details_stats__container.no-border {
        border: none !important;
    }

    .report-stats_value {
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
    }
    .report-details_stat_img__wrapper {
        margin-right: 7px;
    }
    .report-chart__wrapper{
        
    }
}